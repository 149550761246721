import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { findExercicesByDeclarantApi } from "../../_App/Redux/Slices/exercices/exerciceSlice";
import {
  createRetenueCertifParamApi,
  deleteRetenueCertifParamApi,
  findRetenueCertifParamsByExerciceApi,
  retenueCertifParamsListByExercice,
  updateRetenueCertifParamApi,
} from "../../_App/Redux/Slices/retenueCertifParam/retenueCertifParamSlice";
import { currentUserId } from "../../_App/Redux/Slices/user/userSlice";
import { findSocietesByUserIdApi } from "../../_App/Redux/Slices/userSociete/userSociete";
import { RetenueCertifParamType } from "../../_App/Types/Entites/ReteneueCertifParamType";
import {
  CategorieRetenueListListByExercice,
  findAllCategoriesRetenueExerciceApi,
} from "../../_App/Redux/Slices/categorieRetenue/categorieRetenueSlice";
import CategoriesRetenueForm from "../CategoriesRetenue/CategoriesRetenueForm";

interface RetenueCertifParamProps {}

const RetenueCertifParam: React.FC<RetenueCertifParamProps> = () => {
  const [showFormCtegorieRetenue, setshowFormCtegorieRetenue] = useState(false);

  const dispatch = useAppDispatch();
  const retenueCertifParamsList = useAppSelector(
    retenueCertifParamsListByExercice
  );
  const listCategorieRetenue = useAppSelector(
    CategorieRetenueListListByExercice
  );
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<
    Partial<RetenueCertifParamType>
  >({});
  const [selectedExercice, setSelectedExercice] = useState<number | null>(null);
  const userId = useAppSelector(currentUserId);

  const [allExercices, setAllExercices] = useState<any[]>([]);

  useEffect(() => {
    if (userId) {
      dispatch(findSocietesByUserIdApi(userId))
        .unwrap()
        .then((societes) => {
          const allExercices: any[] = [];

          const promises = societes.map((societe: any) => {
            return dispatch(findExercicesByDeclarantApi(societe.id))
              .unwrap()
              .then((exercices) => {
                allExercices.push(...exercices);
              });
          });

          Promise.all(promises).then(() => {
            const uniqueExercices = allExercices.reduce(
              (acc: any[], exercice: any) => {
                if (!acc.some((item) => item.id === exercice.id)) {
                  acc.push(exercice);
                }
                return acc;
              },
              []
            );

            setAllExercices(uniqueExercices);

            const currentYear = new Date().getFullYear();
            const currentYearExercice = uniqueExercices.find(
              (exercice) => exercice.libelle === currentYear.toString()
            );

            if (currentYearExercice) {
              setSelectedExercice(currentYearExercice.id);
            }
          });
        });
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (selectedExercice !== null) {
      dispatch(findRetenueCertifParamsByExerciceApi(selectedExercice));
      dispatch(findAllCategoriesRetenueExerciceApi(selectedExercice));
    }
  }, [selectedExercice]);

  const handleExerciceChange: React.ChangeEventHandler<any> = (e) => {
    const selectedId = parseInt(e.target.value);
    setSelectedExercice(selectedId);
    dispatch(findRetenueCertifParamsByExerciceApi(selectedId));
  };

  const { register, handleSubmit, setValue, reset, watch, getValues } =
    useForm<RetenueCertifParamType>();

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    fieldName: keyof RetenueCertifParamType,
    rowIndex: number
  ) => {
    const { type, checked } = e.target;

    const value = type === "checkbox" ? checked : e.target.value;

    setEditedValues((prevValues: any) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    if (!editableRows.includes(rowIndex)) {
      setEditableRows([...editableRows, rowIndex]);
    }
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...retenueCertifParamsList[rowIndex],
        ...data,
      };

      const response = await dispatch(updateRetenueCertifParamApi(editedData));

      if (response.payload.success) {
        ToastSuccess(response.payload.message);
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      } else {
        ToastWarning("Échec de la mise à jour");
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  const handleDeleteRow = async (id: number) => {
    try {
      await dispatch(deleteRetenueCertifParamApi(id));
    } catch (error) {
      console.error("Delete failed", error);
    }
  };

  const onSubmit: SubmitHandler<RetenueCertifParamType> = async (data) => {
    try {
      data.exercice = selectedExercice || 0;
      const response = await dispatch(createRetenueCertifParamApi(data));
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
        await dispatch(findAllCategoriesRetenueExerciceApi(data.exercice));
        reset();
      } else {
        ToastWarning("Échec de la création");
      }
    } catch (error) {
      console.error("Creation failed", error);
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedModalExercice, setSelectedModalExercice] = useState<
    number | null
  >(null);

  const handleModalExerciceChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setSelectedModalExercice(id);
  };

  const handleModalSubmit = async () => {
    if (selectedModalExercice !== null) {
      const response = await dispatch(
        findRetenueCertifParamsByExerciceApi(selectedModalExercice)
      ).unwrap();
      if (response && response.length > 0) {
        const promises = response.map((param: RetenueCertifParamType) => {
          const { id, ...newParamWithoutId } = param;
          const newParam = {
            ...newParamWithoutId,
            exercice: selectedExercice || 0,
          };
          return dispatch(createRetenueCertifParamApi(newParam));
        });
        await Promise.all(promises);
        ToastSuccess("Les paramètres ont été copiés avec succès.");
      }
      setShowModal(false);
    }
  };

  useEffect(() => {
    const catchoisi = watch("categorie");
  
     if (catchoisi && catchoisi.toString() !== "choisir categorie") {
      const findcategorie = listCategorieRetenue.find(
        (item) => item.id === Number(catchoisi)
      );
      const newcodeRetenue = `${findcategorie?.code}_${findcategorie?.souche
        ?.toString()
        ?.padStart(6, "0")}`;
      setValue("codeRetenue", newcodeRetenue);
    } else {
      setValue("codeRetenue", "");
    }
  }, [watch("categorie")]);
  return (
    <div style={{ overflowX: "auto" }}>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => setShowModal(true)}>
          Copier les paramètres
        </Button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="exerciceSelect">
          <Form.Label>Exercice</Form.Label>
          <Form.Control
            as="select"
            onChange={handleExerciceChange}
            value={selectedExercice || ""}
          >
            {selectedExercice === null && (
              <option value="">Sélectionner un exercice</option>
            )}
            {allExercices.map((exercice) => (
              <option key={exercice.id} value={exercice.id}>
                {exercice.libelle}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th style={{ width: "45px" }}>Code</th>
              <th style={{ width: "45px" }}>Catégorie
              <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  onClick={()=>setshowFormCtegorieRetenue(true)}
                  >
                    
                      <i
                        className="fas fa-plus"
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          color: "green",
                          fontSize: "15px",
                        }}
                      />
                
                  </span>
              </th>

              <th style={{ width: "1200px" }}>Designation</th>
              <th style={{ width: "45px" }}>Taux </th>

              <th style={{ width: "45px" }}>Affectation annexes</th>
              <th style={{ width: "45px" }}>Taux Modifiable</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {retenueCertifParamsList.map(
              (retenueParam: RetenueCertifParamType, rowIndex) => (
                <tr key={retenueParam.id}>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                      }}
                      type="text"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.codeRetenue || retenueParam.codeRetenue
                          : retenueParam.codeRetenue
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "codeRetenue",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                      }}
                      as="select"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.categorie || retenueParam.categorie
                          : retenueParam.categorie
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "categorie",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    >
                      {listCategorieRetenue.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.libelle}{" "}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                      }}
                      type="text"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.designation || retenueParam.designation
                          : retenueParam.designation
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "designation",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                      }}
                      type="number"
                      step="0.1"
                      value={
                        editableRows.includes(rowIndex) &&
                        editedValues.taux !== undefined
                          ? editedValues.taux
                          : retenueParam.taux
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "taux",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                      }}
                      as="select"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.affectationAnx ||
                            retenueParam.affectationAnx
                          : retenueParam.affectationAnx
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "affectationAnx",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    >
                      <option value={1}>Annexe 1</option>
                      <option value={2}>Annexe 2</option>
                      <option value={3}>Annexe 3</option>
                      <option value={4}>Annexe 4</option>
                      <option value={5}>Annexe 5</option>
                      <option value={6}>Annexe 6</option>
                      <option value={7}>Annexe 7</option>
                    </Form.Control>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "8px 0",
                    }}
                  >
                    <Form.Check
                      style={{
                        height: "33px",
                        transform: "scale(1.8)",
                        margin: "auto",
                        marginTop: "16px",
                      }}
                      defaultChecked={
                        editableRows.includes(rowIndex)
                          ? !!editedValues.tauxModifiable
                          : !!retenueParam.tauxModifiable
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e as ChangeEvent<HTMLInputElement>,
                          "tauxModifiable",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <span
                      className={`action-icon ${
                        editableRows.includes(rowIndex) ? "success" : "warning"
                      }`}
                      onClick={() => {
                        if (editableRows.includes(rowIndex)) {
                          handleUpdateRow(rowIndex);
                        } else {
                          handleEditClick(rowIndex);
                        }
                      }}
                      title={
                        editableRows.includes(rowIndex)
                          ? "Enregistrer"
                          : "Modifier"
                      }
                    >
                      <i
                        className={`fas ${
                          editableRows.includes(rowIndex)
                            ? "fa-check"
                            : "fa-edit"
                        }`}
                        style={{
                          color: editableRows.includes(rowIndex)
                            ? "#008000"
                            : "#0000ff",
                          marginRight: "8px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                    <span
                      className="action-icon danger"
                      onClick={() =>
                        retenueParam.id && handleDeleteRow(retenueParam.id)
                      }
                      title="Supprimer"
                    >
                      <i
                        className="fas fa-trash"
                        style={{
                          color: "#ff0000",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </td>
                </tr>
              )
            )}
            <tr>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{
                    fontSize: "1em",
                    height: "33px",
                    width: "150px",
                  }}
                  type="text"
                  {...register("codeRetenue")}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{
                    fontSize: "1em",
                    height: "33px",
                  }}
                  as="select"
                  {...register("categorie")}
                >
                  <option value={"choisir categorie"}>choisir categorie</option>
                  {listCategorieRetenue.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.libelle}{" "}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{
                    fontSize: "1em",
                    height: "33px",
                  }}
                  type="text"
                  {...register("designation")}
                />
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{
                    fontSize: "1em",
                    height: "33px",
                  }}
                  type="number"
                  step="0.1"
                  {...register("taux")}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{
                    fontSize: "1em",
                    height: "33px",
                  }}
                  as="select"
                  {...register("affectationAnx")}
                >
                  <option value={1}>Annexe 1</option>
                  <option value={2}>Annexe 2</option>
                  <option value={3}>Annexe 3</option>
                  <option value={4}>Annexe 4</option>
                  <option value={5}>Annexe 5</option>
                  <option value={6}>Annexe 6</option>
                  <option value={7}>Annexe 7</option>
                </Form.Control>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "8px 0",
                }}
              >
                <Form.Check
                  style={{
                    height: "33px",
                    transform: "scale(1.8)",
                    margin: "auto",
                    marginTop: "16px",
                  }}
                  type="checkbox"
                  {...register("tauxModifiable")}
                />
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sélectionner un exercice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {allExercices.map((exercice) => (
              <Form.Check
                key={exercice.id}
                type="radio"
                label={exercice.libelle}
                checked={selectedModalExercice === exercice.id}
                onChange={(e) => handleModalExerciceChange(e, exercice.id)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleModalSubmit}>
            Enregistrer
          </Button>
        </Modal.Footer>
       
      </Modal>
      <CategoriesRetenueForm
          showModal={showFormCtegorieRetenue}
          setshowModal={setshowFormCtegorieRetenue}
          exercice={allExercices.find(
            (item) => item.id === selectedExercice
          )}
        />
    </div>
  );
};

export default RetenueCertifParam;
