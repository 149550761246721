import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  DeclarationMensuelleList,
  findDeclarationMensuelleBySociete_ExerciceApi,
  ValiderDeclarationMensuelleApi,
} from "../../_App/Redux/Slices/DeclarationMensuelle/DeclarationMensuelleSlice";
import { Table } from "react-bootstrap";
import { Button, Form, Modal } from "react-bootstrap";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

interface EnteteCertificatListProps {
  declarantId: number;
  exerciceId: number;
  showModal: boolean;
  setshowModal: any;
}
export const listmois = [
  { id: "01", mois: "Janvier" },
  { id: "02", mois: "Février" },
  { id: "03", mois: "Mars" },
  { id: "04", mois: "Avril" },
  { id: "05", mois: "Mai" },
  { id: "06", mois: "Juin" },
  { id: "07", mois: "Juillet" },
  { id: "08", mois: "Août" },
  { id: "09", mois: "Septembre" },
  { id: "10", mois: "Octobre" },
  { id: "11", mois: "Novembre" },
  { id: "12", mois: "Décembre" },
];

export const LigneDeclarationMensuelle: React.FC<EnteteCertificatListProps> = ({
  declarantId,
  exerciceId,
  showModal,
  setshowModal,
}) => {
  const dispatch = useAppDispatch();
  const declarationMensuelleList = useAppSelector(DeclarationMensuelleList);

  useEffect(() => {
    if (declarantId && exerciceId) {
      const data = { societeid: declarantId, exerciceid: exerciceId };
      dispatch(findDeclarationMensuelleBySociete_ExerciceApi(data));
    }
  }, [declarantId, exerciceId]);

  const Valider = async(id:string,etat:number)=>{
    const response = await dispatch(ValiderDeclarationMensuelleApi({id:id,etat:etat}))
    if (response.payload.success) {
        ToastSuccess(response.payload.message);
       
  
      } else {
        ToastWarning(response.payload.message);
      }
  }
  return (
    <div>
      <Modal show={showModal} size="lg">
        <Modal.Header closeButton onClick={() => setshowModal(false)}>
          <Modal.Title>List des mois déclarés</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Mois</th>
                <th>Valider</th>
              </tr>
            </thead>
            <tbody>
              {declarationMensuelleList.map((DM: any) => (
                <tr key={DM.id}>
                  <td>{listmois.find(item=>item.id===DM.mois)?.mois}</td>
                  <td>
                    {DM.valider === 1 ? (
                        <Button onClick={()=>{Valider(DM.id,0)}} variant="primary">
                        invalider
                        </Button>
                    ) : (
                        <Button onClick={()=>{Valider(DM.id,1)}} variant="primary">
                            Valider
                            </Button>
                      
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};
