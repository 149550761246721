import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Asterisk } from "../../_App/Helpers/helpers";
import { SubmitHandler, useForm } from "react-hook-form";
import { CategorieRetenueType } from "../../_App/Types/Entites/CategorieRetenueType";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { exercicesListByDeclarant, findExercicesByDeclarantApi } from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { createCategorieRetenueApi } from "../../_App/Redux/Slices/categorieRetenue/categorieRetenueSlice";

export default function CategoriesRetenueForm({
  showModal,
  setshowModal,
  exercice
}: any) {  
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
  libelle: yup.string().required("Le libellé est requis"),
  exercice: yup.number().required("L'exercice est requise"),
  code: yup.string().required("Le code est requis"),
  
});
const [exerciceLibelle,setExerciceLibelle]=useState("")

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<CategorieRetenueType>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(()=>{

    if(exercice){
      setValue("exercice",exercice.id)
      setExerciceLibelle(exercice.libelle)
    }
  },[exercice])

  const onSubmit: SubmitHandler<CategorieRetenueType> = async (data) => {
    try {
 

      const response = await dispatch(createCategorieRetenueApi(data));
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
          setshowModal(false);

      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <Modal show={showModal} size="lg">
      <Modal.Header closeButton onClick={() => setshowModal(false)}>
        <Modal.Title>Gestion des Categories</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
     
          
        <Form.Group controlId="exercice">
            <Form.Label>
              Exercice <Asterisk>*</Asterisk>
              
            </Form.Label>
            <Form.Control
              type="text"
             value={exerciceLibelle}
              className={errors.exercice ? "is-invalid" : ""}
              disabled
            >
             
            </Form.Control>
            {errors.exercice && (
              <Form.Control.Feedback type="invalid">
                {errors.exercice.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="code">
            <Form.Label>
              Code <Asterisk>*</Asterisk>
              
            </Form.Label>
            <Form.Control
               type="text"
              {...register("code")}
              className={errors.code ? "is-invalid" : ""}
            >
              
            </Form.Control>
            {errors.code && (
              <Form.Control.Feedback type="invalid">
                {errors.code.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="libelle">
            <Form.Label>
              Libelle <Asterisk>*</Asterisk>
              
            </Form.Label>
            <Form.Control
               type="text"
              {...register("libelle")}
              className={errors.libelle ? "is-invalid" : ""}
            >
              
            </Form.Control>
            {errors.libelle && (
              <Form.Control.Feedback type="invalid">
                {errors.libelle.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
      
      </Modal.Body>
      <Modal.Footer>
      <Button
        style={{ float: "right", marginTop: "15px" }}
        type="submit"
        variant="primary"
      >
        Enregistrer
      </Button>
     
            </Modal.Footer>
            </Form>
    </Modal>
  );
}
