import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ExportXml from "./ExportXml";
import {
  DeclarationMensuelleList,
  findDeclarationMensuelleBySociete_ExerciceApi,
} from "../../_App/Redux/Slices/DeclarationMensuelle/DeclarationMensuelleSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { listmois } from "../LigneDeclarationMensuelle/LigneDeclarationMensuelle";

interface GenerateXmlModalProps {
  show: boolean;
  onClose: () => void;
  declarantId: number;
  exerciceId: number;
  declarationId:number;
  showcheck_box:any;
  setshowcheck_box:any;
  listnumcertifs:any;
  setlistnumcertifs:any;
  setSelectedMonth:any;
  selectedMonth:any;
  ActeDepot:any;
  setActeDepot:any;
  setdeclarationMensuelle_id:any;
  declarationMensuelle_id:any
}

const GenerateXmlModal: React.FC<GenerateXmlModalProps> = ({
  show,
  onClose,
  declarantId,
  exerciceId,
  declarationId,
  showcheck_box,
  setshowcheck_box,
  listnumcertifs,
  setlistnumcertifs,
  setSelectedMonth,
  selectedMonth,
  ActeDepot,
  setActeDepot,
  setdeclarationMensuelle_id,
  declarationMensuelle_id
}) => {
  const dispatch = useAppDispatch();
  const declarationMensuelleList = useAppSelector(DeclarationMensuelleList);

  useEffect(() => {
    if (declarantId && exerciceId) {
      const data = { societeid: declarantId, exerciceid: exerciceId };
      dispatch(findDeclarationMensuelleBySociete_ExerciceApi(data));
    }
  }, [declarantId, exerciceId]);

  useEffect(() => {
    if (declarationMensuelleList) {
      const findmois = listmois.findIndex(
        (item) => item.id === declarationMensuelleList[0]?.mois
      );
      if (findmois) setSelectedMonth(listmois[findmois + 1].id);
    }
  }, [declarationMensuelleList]);
  const handleMonthSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Sélectionner le mois</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="selectMonth">
          <Form.Label>Mois</Form.Label>
          <Form.Select
            as="select"
            onChange={handleMonthSelect}
            value={selectedMonth}
          >
            <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annuler
        </Button>

        <ExportXml
          selectedMonth={selectedMonth}
          declarantId={declarantId}
          exerciceId={exerciceId}
          onClose={onClose}
          declarationId={declarationId}
          declarationMensuelleList={declarationMensuelleList}
          showcheck_box={showcheck_box}
          setshowcheck_box={setshowcheck_box}
          listnumcertifs={listnumcertifs}
          setlistnumcertifs={setlistnumcertifs}
          ActeDepot={ActeDepot}
       setActeDepot={setActeDepot}
       setdeclarationMensuelle_id={setdeclarationMensuelle_id}
       declarationMensuelle_id= {declarationMensuelle_id}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateXmlModal;
