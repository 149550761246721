import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RetenueCertifParamType } from "../../../Types/Entites/ReteneueCertifParamType";
import { RootState } from "../../store";
import { CategorieRetenueType } from "../../../Types/Entites/CategorieRetenueType";


export const createCategorieRetenueApi = createAsyncThunk(
  "CategorieRetenueRouter/createCategorieRetenueApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "CategorieRetenueRouter/createCategorieRetenueApi",data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllCategoriesRetenueApi = createAsyncThunk(
  "CategorieRetenueRouter/findAllCategoriesRetenue",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "CategorieRetenueRouter/findAllCategoriesRetenue"
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);



export const findAllCategoriesRetenueExerciceApi = createAsyncThunk(
  "CategorieRetenueRouter/findAllCategoriesRetenueByExercice",
  async (exerciceId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `CategorieRetenueRouter/findAllCategoriesRetenueByExercice/${exerciceId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface CategorieRetenue {
  CategorieRetenueList: CategorieRetenueType[];
  CategorieRetenueListByExercie: CategorieRetenueType[];
}

const initialState: CategorieRetenue = {
  CategorieRetenueList: [],
  CategorieRetenueListByExercie: [],
};

export const CategorieRetenueSlice = createSlice({
  name: "CategorieRetenue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
   
    builder.addCase(
      findAllCategoriesRetenueApi.fulfilled,
      (state, action) => {
        state.CategorieRetenueList = action.payload;
      }
    );

    builder.addCase(
      findAllCategoriesRetenueExerciceApi.fulfilled,
      (state, action) => {
        state.CategorieRetenueListByExercie = action.payload;
      }
    );
    builder.addCase(
      createCategorieRetenueApi.fulfilled,
      (state, action) => {
        // Add the new category to the list
        state.CategorieRetenueList.push(action.payload.data);
        state.CategorieRetenueListByExercie.push(action.payload.data);

    
        
      }
    );
    
 
  },
});

export const {} = CategorieRetenueSlice.actions;
export const CategorieRetenueListList = (state: RootState) =>
  state.categorieRetenue.CategorieRetenueList;
export const CategorieRetenueListListByExercice = (state: RootState) =>
  state.categorieRetenue.CategorieRetenueListByExercie;

export default CategorieRetenueSlice.reducer;
