import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  CertificatsBySocieteAndExerciceAndMonth,
  getCertificatBySocieteExerciceAndMonth,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import { getLigneCertificatByNumCertApi } from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";
import { EnteteCertificatType } from "../../_App/Types/Entites/EnteteCertificatType";
import { LigneCertificatType } from "../../_App/Types/Entites/LigneCertificatType";
import {
  findAllPaysDevisesApi,
  selectPaysDevisesList,
} from "../../_App/Redux/Slices/paysDevise/paysDeviseSlice";
import { DeclarationMensuelleType } from "../../_App/Types/Entites/DeclarationMensuelleType";


import Swal from "sweetalert2";

interface ExportXmlProps {
  selectedMonth: string;
  declarantId: number;
  exerciceId: number;
  declarationMensuelleList: DeclarationMensuelleType[];
  onClose: () => void;
  declarationId:any;
  showcheck_box:any;
  setshowcheck_box:any;
  listnumcertifs:any;
  setlistnumcertifs:any;
  ActeDepot:any;
  setActeDepot:any;
  setdeclarationMensuelle_id:any;
  declarationMensuelle_id:any
}

const ExportXml: React.FC<ExportXmlProps> = ({
  selectedMonth,
  declarantId,
  exerciceId,
  declarationMensuelleList,
  onClose,
  declarationId,
  showcheck_box,
  setshowcheck_box,
  listnumcertifs,
  setlistnumcertifs,
  ActeDepot,
  setActeDepot,
  setdeclarationMensuelle_id,
  declarationMensuelle_id
 
}) => {
  const dispatch = useAppDispatch();
  const certificatsList = useAppSelector(
    CertificatsBySocieteAndExerciceAndMonth
  );
  const paysDevisesList = useAppSelector(selectPaysDevisesList);

  useEffect(() => {
    dispatch(findAllPaysDevisesApi());
  
  }, [selectedMonth, declarantId, exerciceId, dispatch]);

  const handleExportXml = async () => {

    if (declarationMensuelleList && selectedMonth) {
      const find = declarationMensuelleList.find(
        (item) => item.mois === selectedMonth
      );
      if (find) {
        Swal.fire({
          title: `Cette déclaration était déjà faite il est ${
            find.valider ? "validé" : "non validé"
          }`,
          text: "vous vouliez la modifier ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmer",
        }).then((result) => {
          if (result.isConfirmed) {
            setActeDepot(1);
            setdeclarationMensuelle_id(find?.id);
            setshowcheck_box(true);

          }
        });
      }else{
        setshowcheck_box(true);

      }
      dispatch(
        getCertificatBySocieteExerciceAndMonth({
          declaration:declarationId,
          societeId: declarantId,
          exerciceId: exerciceId,
          month: parseInt(selectedMonth),
        })
      );
      onClose();

    }
  };



  return (
    <Button variant="info" onClick={handleExportXml}>
      Exporter XML
    </Button>
  );
};

export default ExportXml;
