import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
  Button,
  Card,
  CardTitle,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  CertificatsBySociete,
  createEnteteCertificatApi,
  getAllEnteteCertificatsApi,
  getEnteteCertificatByNumCertApi,
  getEnteteCertificatBySocieteIdApi,
  selectCurrentEnteteCertificat,
  updateEnteteCertificatApi,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import {
  createLigneCertificatApi,
  getLigneCertificatByCertificatIdApi,
  LignesCertificatByCertificatId,
} from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";
import { findAllAdditionalTaxesApi } from "../../_App/Redux/Slices/additionalTax/additionalTaxSlice";
import {
  BeneficiairesListBySociete,
  getBeneficiaireBySocieteIdsApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  findRetenueCertifParamsByExerciceApi,
  retenueCertifParamsListByExercice,
} from "../../_App/Redux/Slices/retenueCertifParam/retenueCertifParamSlice";
import {
  findByIdApi,
  selectCurrentSociete,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { TVAList, findAllTVAApi } from "../../_App/Redux/Slices/tva/tvaSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { BeneficiaireType } from "../../_App/Types/Entites/BeneficiaireType";
import { RetenueCertifParamType } from "../../_App/Types/Entites/ReteneueCertifParamType";
import { EnteteCertificatDataType } from "../../_App/Types/Forms/EnteteCertificatType";
import { LigneCertificatDataType } from "../../_App/Types/Forms/LigneCertificatDataType";
import BeneficiaireCertifAdd from "./BenificiaireCertifAdd";
import ImpressionCertificat from "./ImpressionCetificat";
import {
  exercicesListByDeclarant,
  findExercicesByDeclarantApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { formatFloat } from "../../_App/Helpers/helpers";
import { EnteteCertificatType } from "../../_App/Types/Entites/EnteteCertificatType";
import { CategorieRetenueListListByExercice, findAllCategoriesRetenueExerciceApi } from "../../_App/Redux/Slices/categorieRetenue/categorieRetenueSlice";

const CustomModal = styled(Modal)`
  .modal-dialog {
    width: 73%;
    max-width: none;
  }
`;

const StyledCheckbox = styled(Form.Check)`
  transform: scale(1.8);
  margin-left: 40px;

  .form-check-input:checked {
    background-color: green;
    border-color: green;
  }
`;
const StyledCheckbox2 = styled(Form.Check)`
  transform: scale(1.8);
  margin-left: 60px;

  .form-check-input:checked {
    background-color: green;
    border-color: green;
  }
`;

const StyledCheckbox3 = styled(Form.Check)`
  transform: scale(1.8);
  margin-left: 20px;

  .form-check-input:checked {
    background-color: green;
    border-color: green;
  }
`;

interface CertificatRetenueFormProps {
  declarantId?: number;
  exerciceId?: number;
  initialData?: LigneCertificatDataType;
  declarationId: number;
  num_Certificat?: string;
}

const CertificatRetenueForm: React.FC<CertificatRetenueFormProps> = ({
  declarantId,
  exerciceId,
  declarationId,
  num_Certificat,
}) => {
  const {
    register,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    getValues,

    formState: { errors },
  } = useForm<LigneCertificatDataType>();

  const currentCertificat = useAppSelector(selectCurrentEnteteCertificat);
  const ligneCertificat = useAppSelector(LignesCertificatByCertificatId);
  const listCategorieRetenue = useAppSelector(
    CategorieRetenueListListByExercice
  );
  const retenueParams = useAppSelector(retenueCertifParamsListByExercice);
  const listExercice = useAppSelector(exercicesListByDeclarant);
  const dispatch = useAppDispatch();

  const certificats = useAppSelector(CertificatsBySociete);
  const tvaList = useAppSelector(TVAList);
  const currentSociete = useAppSelector(selectCurrentSociete);

  useEffect(() => {
    if (num_Certificat) {
      dispatch(getEnteteCertificatByNumCertApi(num_Certificat));
      dispatch(getLigneCertificatByCertificatIdApi(num_Certificat));
    }
  }, [num_Certificat, dispatch]);

  useEffect(() => {
    if (declarantId) {
      dispatch(findByIdApi(declarantId));
      dispatch(findExercicesByDeclarantApi(declarantId));
    }
  }, [dispatch, declarantId]);

  useEffect(() => {
   
    dispatch(getEnteteCertificatBySocieteIdApi(declarantId ?? 0));
    dispatch(findAllTVAApi());
    dispatch(findAllAdditionalTaxesApi());
  }, [dispatch]);

  useEffect(() => {

    if (exerciceId) {
      dispatch(findRetenueCertifParamsByExerciceApi(exerciceId));
    }
  }, [exerciceId]);

  const [tauxModifiable, setTauxModifiable] = useState(true);
  const [affectationAnx, setAffectationAnx] = useState<number | undefined>(
    undefined
  );

  const handleCodeRetenueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
  
    const selectedCodeRetenue = event.target.value;
    const selectedParam = retenueParams.find(
      (param) => param.codeRetenue === selectedCodeRetenue
    );
    if (selectedParam) {
      setValue("taux", selectedParam.taux);
      setTauxModifiable(selectedParam.tauxModifiable);
      setAffectationAnx(selectedParam.affectationAnx);
    } else {
      setAffectationAnx(undefined);
    }
  };

  const [isValid, setisValid] = useState(false);
  const [selectedBeneficiaire, setSelectedBeneficiaire] = useState<
    BeneficiaireType | undefined
  >(undefined);

  const beneficiaires = useAppSelector(BeneficiairesListBySociete);
  useEffect(() => {
    if (declarantId) {
      dispatch(getBeneficiaireBySocieteIdsApi([declarantId]));
    }
  }, [declarantId]);

  const [showBeneficiaireModal, setShowBeneficiaireModal] = useState(false);

  const handleShowBeneficiaireModal = () => setShowBeneficiaireModal(true);
  const handleCloseBeneficiaireModal = () => setShowBeneficiaireModal(false);

  const montant = watch("montant", 0);
  const taux = watch("taux", 0);
  const montantTTC = watch("montantTtc", 0);
  useEffect(() => {
   
    if (currentCertificat && num_Certificat && ligneCertificat) {
      const certif = { ...currentCertificat };
      setSelectedBeneficiaire(currentCertificat.Beneficiaire);
      const selectedDate = certif.date;
      setSelectedDate(selectedDate);
      setNumCertificat(currentCertificat?.numCertificat);
      enteteForm.reset(certif);
      setLignesCertificats(ligneCertificat);
    }
  }, [currentCertificat, ligneCertificat, num_Certificat]);

  useEffect(() => {

    const tauxValue = taux || 0;
    const montantTtcValue = montantTTC || 0;

    if (!isNaN(montantTtcValue) && !isNaN(tauxValue)) {
      const retenue = (montantTtcValue * tauxValue) / 100;
      setValue("mntRetenue", formatFloat(retenue));
      setValue("netServi", formatFloat(montantTtcValue - retenue));
    }

  }, [montantTTC, taux, setValue]);

  const tauxTva = watch("tauxTva", 19);

  useEffect(() => {
 
    const montantValue = montant || 0;
    const tauxTvaValue = tauxTva || 0;

    if (
      !isNaN(montantValue) &&
      !isNaN(tauxTvaValue) &&
      montantValue > 0 &&
      tauxTvaValue > 0
    ) {
      const montantTva = (montantValue * tauxTvaValue) / 100;
      setValue("montantTva", parseFloat(montantTva.toFixed(3)));
    } else {
      setValue("montantTva", 0);
    }

  }, [montant, tauxTva, setValue]);

  const montantTva = watch("montantTva", 0);

  useEffect(() => {
   
    const montantValue = parseFloat(String(montant)) || 0;
    const montantTvaValue = parseFloat(String(montantTva)) || 0;
    const montantTtcValue = montantValue + montantTvaValue;
    setValue("montantTtc", formatFloat(montantTtcValue));
 
  }, [montant, montantTva, setValue]);

  const handleTauxChange = (event: any) => {

    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 2) {
      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
    if (value === "") {
      value = "0";
    }
    event.target.value = value;
    handleInputTauxChangeAndValidation("taux", value);

  };

  const handleInputTauxChangeAndValidation = (name: any, value: any) => {

    const regex = /^\d{0,3}(\.\d{1,2})?$/;
    if (regex.test(value) || value === "") {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message: "Veuillez saisir un taux valide au format xx.xx",
      });
    }
  };

  const [tauxDevise, setTauxDevise] = useState<number | undefined>(
    selectedBeneficiaire?.Pdevise?.taux
  );

  useEffect(() => {
    if (selectedBeneficiaire?.Pdevise?.taux !== undefined) {
      setTauxDevise(selectedBeneficiaire.Pdevise.taux);
      setValue("tauxDevise", selectedBeneficiaire.Pdevise.taux);
    }
  }, [selectedBeneficiaire, setValue]);

  const handleTauxDeviseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 2) {
      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
    if (value === "") {
      value = "0";
    }
    event.target.value = value;
    setTauxDevise(parseFloat(value));
    handleInputTauxDeviseChangeAndValidation("tauxDevise", value);
  };

  const handleInputTauxDeviseChangeAndValidation = (
    name: any,
    value: string
  ) => {

    const regex = /^\d{0,3}(\.\d{1,2})?$/;
    if (regex.test(value) || value === "") {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message: "Veuillez saisir un taux valide au format xxx.xx",
      });
    }
  };

  const handleInputChangeAndValidation = (
    name: any,
    value: any,
    decimalPlaces = 3
  ) => {
    // Créez une expression régulière pour valider les valeurs numériques avec un maximum de decimalPlaces décimales
    const regex = new RegExp(`^\\d+(\\.\\d{1,${decimalPlaces}})?$`);

    if (value === "") {
      // Si la valeur est vide, définissez-la comme 0.000
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      // Si la valeur est valide, formatez-la avec decimalPlaces décimales
      setValue(name, formatFloat(value));
      clearErrors(name);
    } else {
      // Si la valeur est invalide, définissez une erreur
      setError(name, {
        type: "manual",
        message: `Veuillez saisir un nombre valide avec un maximum de ${decimalPlaces} chiffres après le point décimal.`,
      });
    }
  };

  const years = Array.from({ length: 11 }, (_, index) => 2020 + index);

  const handleMontantChange = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 3) {
      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
    }
    event.target.value = value;

    handleInputChangeAndValidation("montant", value, 3);
  };

  const handleNetServiChange = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 3) {
      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
    }
    event.target.value = value;
    handleInputChangeAndValidation("netServi", value, 3);
  };

  const handleMntRetenueChange = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 3) {
      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
    }
    event.target.value = value;
    handleInputChangeAndValidation("mntRetenue", value, 3);
  };

  const handleReset = () => {
    
    reset({
      additionalTax: false,
      categorieRetenue: "",
      cnps: false,
      codeRetenue: "",
      mntRetenue: undefined,
      objet: "",
      montant:0,
      taux: 0,
      netServi: 0,
      societe: 0,
      beneficiaire: 0,
      exercice: 0,
      paramRetenue: 0,
      certificat: "",
      declaration: 0,
      anneFacturation: "",
      priseEnCharge: false,
      tauxTva: 0,
      montantTva: 0,
      montantTtc: 0,
      montantTaxeAdd: 0,
      tauxTaxeAdd: 0,
      devise : "",
      tauxDevise: 0, 
      codeTaxeAdd: "",
      mntBaseTaxeAdd: 0,
      netServiTaxeAdd: 0 ,

    })  
  };

  ///////////////////////

  const [numCertificat, setNumCertificat] = useState<string>("");

  const getNextCertificatNumber = (certificats: any, currentSociete: any) => {
    if (!currentSociete || !currentSociete.souche) {
      throw new Error("La souche de la société courante n'est pas disponible");
    }

    const prefix = currentSociete.souche;

    if (certificats.length > 0) {
      const lastCertificat = certificats[certificats.length - 1];
      const lastCertificatNumber = lastCertificat.numCertificat
        ? parseInt(lastCertificat.numCertificat.substr(prefix.length))
        : 0;
      return `${prefix}${String(lastCertificatNumber + 1).padStart(4, "0")}`;
    }

    return `${prefix}0001`;
  };

  useEffect(() => {
    if (currentSociete&&!num_Certificat) {
      setNumCertificat(getNextCertificatNumber(certificats, currentSociete));
    }
  }, [certificats, currentSociete,num_Certificat]);

  const enteteForm = useForm<EnteteCertificatDataType>();
  const handleCombinedSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      if (lignesCertificats.length === 0) {
        ToastWarning("La liste des lignes de certificat est vide");
        return;
      }

      const enteteData = enteteForm.getValues();
      enteteData.montantTotal = totalMontant;
      enteteData.retenueTotal = totalRetenue;
      enteteData.totalMontantTtc = totalTtc;
      enteteData.totalMontantTva = totalTva;
      enteteData.totalTaxes = totalTaxAdd;

      enteteData.netServiTotal = totalNetServi;
      if(!num_Certificat){
        enteteData.numCertificat = numCertificat;

        enteteData.exercice = exerciceId ?? 0;
        enteteData.societe = declarantId ?? 0;
        enteteData.declaration = declarationId ?? 0;
      }
  
      const listligne = lignesCertificats.map((item) => {
        return { ...item, beneficiaire: enteteData.beneficiaire };
      });
      let data
      let enteteResponse;
        
      if (num_Certificat) {
         data = {numCertificat :num_Certificat, entet: enteteData, lignes: listligne };

        enteteResponse = await dispatch(updateEnteteCertificatApi(data));

      } else {
        data = {entet: enteteData, lignes: listligne };

        enteteResponse = await dispatch(createEnteteCertificatApi(data));
  
      }

      if (enteteResponse.payload.success) {
        const numCertificat = enteteResponse.payload.data.numCertificat;

        ToastSuccess(enteteResponse.payload.message);
        enteteForm.reset();
        setLignesCertificats([]);
        setSelectedDate("");
        setNumCertificat(getNextCertificatNumber(certificats, currentSociete));

        dispatch(getAllEnteteCertificatsApi(null));
        handleShowModal(numCertificat);
      } else {
        console.error("Error submitting entete data");
        ToastWarning("Erreur de l'ajout de  certificat");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  function truncateText(text: any, maxLength: any) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  }

  const [lignesCertificats, setLignesCertificats] = useState<any[]>([]);

  const handleDateChange = (e: any) => {
    const selectedDate = e.target.value;
    const year = new Date(selectedDate).getFullYear().toString();
    setSelectedDate(selectedDate);
  };
  const handleAjouterLigne = () => {
    const ligneData = watch();
    const selectedParam = retenueParams.find(
      (param) => param.codeRetenue === ligneData.codeRetenue
    );

    ligneData.devise = selectedBeneficiaire?.Pdevise?.libelleDevise;
    ligneData.declaration = declarationId ?? 0;
    ligneData.paramRetenue = selectedParam?.id ?? 0;
    ligneData.beneficiaire = selectedBeneficiaire?.id ?? 0;
    ligneData.certificat = numCertificat;
    ligneData.exercice = exerciceId ?? 0;
    ligneData.societe = declarantId ?? 0;

    const findindex = lignesCertificats.findIndex(item=>item?.id === ligneData?.id)
    otherFieldsSchema
      .validate(ligneData)
      .then(() => {
        if(findindex !== -1){
          const copieList = [...lignesCertificats]
          copieList[findindex] = ligneData
          setLignesCertificats(copieList);

        }else{
          setLignesCertificats([...lignesCertificats, ligneData]);

        }
        reset({
          additionalTax: false,
          categorieRetenue: "",
          cnps: false,
          codeRetenue: "",
          mntRetenue: undefined,
          objet: "",
          montant:0,
          taux: 0,
          netServi: 0,
          societe: 0,
          beneficiaire: 0,
          exercice: 0,
          paramRetenue: 0,
          certificat: "",
          declaration: 0,
          anneFacturation: "",
          priseEnCharge: false,
          tauxTva: 0,
          montantTva: 0,
          montantTtc: 0,
          montantTaxeAdd: 0,
          tauxTaxeAdd: 0,
          devise : "",
          tauxDevise: 0, 
          codeTaxeAdd: "",
          mntBaseTaxeAdd: 0,
          netServiTaxeAdd: 0 ,
    
        })  
        const findyear = listExercice.find((item) => item.id === exerciceId);
        if (findyear) {
          setValue("anneFacturation", findyear.libelle);
        }
        setShowAdditionalForm(false);
      })
      .catch((error) => {
        console.error("Validation error:", error.message);
        ToastWarning("Tous les champs sont obligatoire");
      });
  };

  const handleSupprimerLigne = (index: any) => {
    const nouvellesLignes = [...lignesCertificats];
    nouvellesLignes.splice(index, 1);
    setLignesCertificats(nouvellesLignes);
  };

  const calculateTotals = () => {
    const totalMontant = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) => acc + (Number(ligne.montant) || 0),
        0
      )
    );
    const totalTtc = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) => acc + (Number(ligne.montantTtc) || 0),
        0
      )
    );

    const totalTva = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) => acc + (Number(ligne.montantTva) || 0),
        0
      )
    );

    const totalRetenue = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) =>
          acc +
          ((Number(ligne.mntRetenue) || 0) +
            (Number(ligne.montantTaxeAdd) || 0)),

        0
      )
    );
    const totalNetServi = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) =>
          acc +
          ((Number(ligne.netServi) || 0) +
            (Number(ligne.netServiTaxeAdd) || 0)),
        0
      )
    );

    const totalTaxAdd = formatFloat(
      lignesCertificats.reduce(
        (acc, ligne) => acc + (Number(ligne.montantTaxeAdd) || 0),
        0
      )
    );

    return {
      totalMontant,
      totalRetenue,
      totalNetServi,
      totalTva,
      totalTtc,
      totalTaxAdd,
    };
  };

  const {
    totalMontant,
    totalRetenue,
    totalNetServi,
    totalTva,
    totalTtc,
    totalTaxAdd,
  } = calculateTotals();

  const otherFieldsSchema = Yup.object().shape({
    taux: Yup.number().required("Le taux est requis"),
    codeRetenue: Yup.string().required("Le code de retenue est requis"),
    montant: Yup.number().required("Le montant est requis"),
    mntRetenue: Yup.number().required("Le montant de retenue est requis"),
    netServi: Yup.number().required("Le montant net servi est requis"),
    objet: Yup.string(),
    anneeFacturation: Yup.string().test(
      "is-four-digits",
      "Veuillez saisir exactement 4 chiffres.",
      (value) => {
        const regex = /^\d{4}$/;
        return value ? regex.test(value) : true;
      }
    ),
  });

  const [selectedDate, setSelectedDate] = useState("");
  useEffect(() => {
    if (selectedBeneficiaire && selectedDate) {
      setisValid(true);
    }
  }, [selectedBeneficiaire, selectedDate]);

  const [showModal, setShowModal] = useState(false);
  const [currentNumCertificat, setCurrentNumCertificat] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (numCertificat: any) => {
    setCurrentNumCertificat(numCertificat);
    setShowModal(true);
  };

  const [selectedCategorie, setSelectedCategorie] = useState<string>("");

  const handleCategorieChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategorie(event.target.value);
  };

  const [showAdditionalForm, setShowAdditionalForm] = useState(false);

  const handleTaxeAdditionalChange = (e: any) => {
    setShowAdditionalForm(!showAdditionalForm);
  };

  const [selectedParam2, setSelectedParam2] =
    useState<RetenueCertifParamType | null>(null);

  const [montantTaxeAdd, setMontantTaxeAdd] = useState<number | string>(0);

  useEffect(() => {
    if (listExercice.length > 0) {
      const findyear = listExercice.find((item) => item.id === exerciceId);
      if (findyear) {
        setValue("anneFacturation", findyear.libelle);
      }
    }
   if(exerciceId) dispatch(findAllCategoriesRetenueExerciceApi(exerciceId));

  }, [listExercice, exerciceId]);
  useEffect(() => {
    const montantTva = watch("montantTva") || 0;
    const taux = selectedParam2?.taux || 0;
    const calculatedTaxeAdd = parseFloat(
      ((montantTva * taux) / 100).toFixed(3)
    );

    setMontantTaxeAdd(calculatedTaxeAdd);
    setValue("montantTaxeAdd", calculatedTaxeAdd);
    setValue("tauxTaxeAdd", selectedParam2?.taux);
  }, [watch("montantTva"), selectedParam2, setValue]);

  const handleMontantTaxeAddChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const [integerPart, decimalPart] = value.split(".");

    if (decimalPart && decimalPart.length > 3) {
      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
    }

    setMontantTaxeAdd(formatFloat(value));
    setValue("montantTaxeAdd", formatFloat(value));
  };

  const [netServi2, setNetServi2] = useState<number | string>(0);
  let mntBaseTaxeAdd = watch("montantTva") || 0;

  useEffect(() => {
    const calculatedTaxeAdd = formatFloat(montantTaxeAdd as number) || 0;
    const calculatedNetServi2 = formatFloat(mntBaseTaxeAdd - calculatedTaxeAdd);

    setMontantTaxeAdd(calculatedTaxeAdd);
    setNetServi2(calculatedNetServi2);

    setValue("mntBaseTaxeAdd", formatFloat(mntBaseTaxeAdd));

    setValue("montantTaxeAdd", calculatedTaxeAdd);
    setValue("netServiTaxeAdd", calculatedNetServi2);
  }, [mntBaseTaxeAdd, montantTaxeAdd, setValue]);

  const Selectligne = (index: number) => {
    setSelectedCategorie(lignesCertificats[index].categorieRetenue);
    if (
      lignesCertificats[index].montantTaxeAdd &&
      lignesCertificats[index].montantTaxeAdd !== "" &&
      lignesCertificats[index].montantTaxeAdd !== 0
    ) {
      setShowAdditionalForm(true);
    }
    reset(lignesCertificats[index]);
  };

  return (
    <>
      <Card
        className="mt-3"
        style={{ backgroundColor: isValid ? "#b7e4c7" : "#ffe6e6" }}
      >
        <Card.Body>
          <Form onSubmit={handleCombinedSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="numCertificat">
                  <Form.Label>Numéro de certificat</Form.Label>
                  <Form.Control type="text" value={numCertificat} readOnly />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="beneficiaire">
                  <Form.Label>
                    Bénéficiaire{" "}
                    <span
                      onClick={handleShowBeneficiaireModal}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      <i
                        className="fas fa-plus"
                        style={{ marginRight: "5px", color: "green" }}
                      />{" "}
                    </span>
                  </Form.Label>
                  <Form.Select
                    {...enteteForm.register("beneficiaire")}
                    onChange={(e) =>
                      setSelectedBeneficiaire(
                        beneficiaires.find(
                          (b) => b.id === parseInt(e.target.value)
                        )
                      )
                    }
                    style={{
                      backgroundColor: selectedBeneficiaire ? "" : "#ffe6e6",
                    }}
                  >
                    <option value="">Sélectionner un bénéficiaire</option>
                    {beneficiaires.map((beneficiaire: BeneficiaireType) => (
                      <option key={beneficiaire.id} value={beneficiaire.id}>
                        {beneficiaire.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    {...enteteForm.register("date")}
                    onChange={handleDateChange}
                    style={{ backgroundColor: selectedDate ? "" : "#ffe6e6" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div style={{ marginTop: "30px" }}>
              <Row>
                <Col>
                  <strong>Total Montant HT:</strong> {totalMontant}
                </Col>
                <Col>
                  <strong>Total Tva:</strong> {totalTva}
                </Col>
                <Col>
                  <strong>Total Ttc:</strong> {totalTtc}
                </Col>
                <Col>
                  <strong>Total Retenue:</strong> {totalRetenue}
                </Col>
                <Col>
                  <strong>Total Net Servi:</strong> {totalNetServi}
                </Col>
              </Row>
            </div>
            <Modal show={showBeneficiaireModal} size="lg">
              <Modal.Header closeButton onClick={handleCloseBeneficiaireModal}>
                <Modal.Title>Ajouter un bénéficiaire</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BeneficiaireCertifAdd
                  declarantId={declarantId}
                  closeForm={handleCloseBeneficiaireModal}
                />
              </Modal.Body>
            </Modal>
          </Form>
        </Card.Body>
      </Card>
      <Form onSubmit={handleCombinedSubmit}>
        <Card className="mt-3">
          <CardTitle style={{ marginLeft: "20px", marginTop: "10px" }}>
            {" "}
            Lignes de certificat
          </CardTitle>
          <Card.Body>
            {lignesCertificats.map((ligne, index) => {
              return (
                <Row
                  key={index}
                  className="mb-3"
                  style={{ backgroundColor: "#e5e7e8", padding: "5px" }}
                  onClick={() => Selectligne(index)}
                >
                  <Col>Code retenue : {ligne.codeRetenue}</Col>
                  <Col>Montant HT : {ligne.montant}</Col>
                  <Col>Montant TTC : {ligne.montantTtc}</Col>
                  <Col>Taux Tva : {ligne.tauxTva}</Col>
                  <Col>Montant Tva : {ligne.montantTva}</Col>
                  <Col>Taux RS : {ligne.taux}</Col>
                  <Col>Montant Retenue : {ligne.mntRetenue}</Col>
                  <Col>Net Servi : {ligne.netServi}</Col>

                  <Col>
                    <span
                      className="action-icon danger"
                      onClick={() => handleSupprimerLigne(index)}
                      title="Retirer cette ligne"
                    >
                      <i
                        className="fas fa-times"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </Col>
                  {ligne.montantTaxeAdd !== 0 && (
                    <Row>
                      {" "}
                      <p style={{ fontWeight: "bolder" }}>
                        Taxe Additionnelle :
                      </p>
                      <Col>
                        Montant base retenu additionelle :{" "}
                        {ligne.mntBaseTaxeAdd}
                      </Col>
                      <Col> Taux RS additionelle: {ligne.tauxTaxeAdd}</Col>
                      <Col>
                        Montant Retenue additionelle: {ligne.montantTaxeAdd}
                      </Col>
                      <Col>
                        Montant Servi apres taxe additionelle:{" "}
                        {ligne.netServiTaxeAdd}
                      </Col>
                      <Col>
                        Montant Servi Total:{" "}
                        {formatFloat(
                          (Number(ligne.netServi) || 0) +
                            (Number(ligne.netServiTaxeAdd) || 0)
                        )}
                      </Col>
                    </Row>
                  )}
                </Row>
              );
            })}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group controlId="anneeFacturation">
                  <Form.Label>Année de facturation :</Form.Label>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    {...register("anneFacturation")}
                    onChange={(e) =>
                      setValue("anneFacturation", e.target.value)
                    }
                    className={errors.anneFacturation ? "is-invalid" : ""}
                  >
                    {listExercice &&
                      listExercice.map((item, index) => (
                        <option key={index} value={item.libelle}>
                          {item.libelle}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Form.Group controlId="cnps" style={{ marginRight: "45px" }}>
                    <Form.Label>CNPC</Form.Label>
                    <StyledCheckbox type="checkbox" {...register("cnps")} />
                  </Form.Group>
                  <Form.Group controlId="priseEnCharge">
                    <Form.Label>Prise en Charge</Form.Label>
                    <StyledCheckbox2
                      type="checkbox"
                      {...register("priseEnCharge")}
                    />
                  </Form.Group>
                </div>
              </Col>

              <Col>
                <Form.Group controlId="objet">
                  <Form.Label>Objet</Form.Label>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    {...register("objet")}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="categorieRetenue">
                  <Form.Label>Type de retenue</Form.Label>
                  <Form.Select
                    {...register("categorieRetenue")}
                    onChange={handleCategorieChange}
                  >
                     <option value={"choisir categorie"}>choisir categorie</option>
                  {listCategorieRetenue.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.libelle}{" "}
                    </option>
                  ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="codeRetenue">
                  <Form.Label>Retenue</Form.Label>
                  <Form.Select
                    {...register("codeRetenue")}
                    onChange={handleCodeRetenueChange}
                  >
                    <option value="">Sélectionner un retenue</option>
                    {retenueParams
                      .filter((param) => param.categorie.toString() === selectedCategorie)
                      .map((param: RetenueCertifParamType) => (
                        <option key={param.id} value={param.codeRetenue}>
                          {param.codeRetenue} -{" "}
                          {truncateText(param.designation, 200)} - {param.taux}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {(affectationAnx === 2 ||
                affectationAnx === 4 ||
                affectationAnx === 6) && (
                <Col>
                  <Col>
                    <Form.Group controlId="typeMontant">
                      <Form.Label>Type montant </Form.Label>
                      <Form.Select {...register("typeMontant")}>
                        <option value="">Sélectionner un type</option>
                        {affectationAnx === 2 && (
                          <>
                            <option value={1}>1 : Honoraires</option>
                            <option value={2}>2 : Commissions</option>
                            <option value={3}>3 : Courtages</option>
                            <option value={4}>4 : Loyers</option>
                            <option value={5}>
                              5 : Rémunération non commerciale
                            </option>
                            <option value={6}>
                              6 : Rémunération de la performance dans la
                              prestation
                            </option>
                            <option value={7}>
                              7 : Commissions des distributeurs agrées des
                              opérateurs téléphoniques.
                            </option>
                            <option value={0}>0 : Autres</option>
                          </>
                        )}
                        {affectationAnx === 4 && (
                          <>
                            <option value={1}>1 : Pour les honoraires</option>
                            <option value={2}>2 : Pour les commissions</option>
                            <option value={3}>3 : Pour les courtages</option>
                            <option value={4}>4 : Pour les loyers</option>
                            <option value={5}>
                              5 : Pour les rémunérations au titre des activités
                              non commerciales
                            </option>
                            <option value={6}>
                              6 : Pour les autres revenus
                            </option>
                            <option value={7}>
                              7 : Les Mnt servis aux non résidents qui ne
                              procèdent pas au dépôt de la déclaration
                              d’existence avant d’entamer leur activité :
                              personnes établies en Tun et résidentes dans un
                              Etat dont le régime fiscal est privilégié
                            </option>
                            <option value={8}>
                              8 : Pour les Mnt servis aux non-résidents qui ne
                              procèdent pas au dépôt de la déclaration
                              d’existence avant d’entamer leur activité : autres
                              établissements stables
                            </option>
                            <option value={9}>
                              9 : Rémunérations payées en contrepartie de la
                              performance...
                            </option>
                            <option value={0}>
                              0 : Si le montant A413 = 0
                            </option>
                          </>
                        )}

                        {affectationAnx === 6 && (
                          <>
                            <option value={0}>
                              0 : Si le montant ristourne est null
                            </option>
                            <option value={1}>
                              1 : Ristournes Commerciales
                            </option>
                            <option value={2}>
                              2 : Ristournes non Commerciales
                            </option>
                          </>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="montant">
                  <Form.Label>Montant HT</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("montant")}
                    onChange={(e) => {
                      setTimeout(() => {
                        handleMontantChange(e);
                      }, 3000);
                    }}
                    style={{ fontSize: "1em", height: "33px" }}
                    placeholder="0.00"
                  />
                  {errors.montant && (
                    <span style={{ color: "red" }}>
                      {errors.montant.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="taux">
                  <Form.Label>Taux rs </Form.Label>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    {...register("taux")}
                    disabled={!tauxModifiable}
                    onChange={handleTauxChange}
                  />
                  {errors.taux && (
                    <span style={{ color: "red" }}>{errors.taux.message}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="tauxTva">
                  <Form.Label>Taux TVA</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ fontSize: "1em", height: "33px" }}
                    {...register("tauxTva")}
                  >
                    {tvaList.map((tva) => (
                      <option key={tva.id} value={tva.taux}>
                        {tva.taux}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.tauxTva && (
                    <span style={{ color: "red" }}>
                      {errors.tauxTva.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="montantTva">
                  <Form.Label>Montant TVA</Form.Label>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    {...register("montantTva")}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="montantTtc">
                  <Form.Label>Montant TTC</Form.Label>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    {...register("montantTtc")}
                    disabled={true}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="mntRetenue">
                  <Form.Label>Montant de retenue</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("mntRetenue")}
                    onChange={(e) => {
                      setTimeout(() => {
                        handleMntRetenueChange(e);
                      }, 3000);
                    }}
                    disabled={true}
                    style={{ fontSize: "1em", height: "33px" }}
                    placeholder="0.00"
                  />
                  {errors.mntRetenue && (
                    <span style={{ color: "red" }}>
                      {errors.mntRetenue.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="netServi">
                  <Form.Label>Montant net servi</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("netServi")}
                    onChange={handleNetServiChange}
                    style={{ fontSize: "1em", height: "33px" }}
                    placeholder="0.00"
                  />
                  {errors.netServi && (
                    <span style={{ color: "red" }}>
                      {errors.netServi.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>{" "}
            {selectedBeneficiaire && selectedBeneficiaire.Pdevise && (
              <Row>
                <Col>
                  <Form.Group controlId="devise">
                    <Form.Label>Devise</Form.Label>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      type="text"
                      {...register("devise")}
                      value={selectedBeneficiaire?.Pdevise?.libelleDevise}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="tauxDevise">
                    <Form.Label>Taux Devise</Form.Label>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      type="text"
                      value={tauxDevise !== undefined ? tauxDevise : ""}
                      onChange={handleTauxDeviseChange}
                    />
                    {errors.tauxDevise && (
                      <span style={{ color: "red" }}>
                        {errors.tauxDevise.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm="auto">
                <Form.Group
                  controlId="additionalTax"
                  className="d-flex align-items-center"
                >
                  <Form.Label style={{ marginTop: "45px" }} className="me-2">
                    Taxe additionnelle
                  </Form.Label>
                  <StyledCheckbox3
                    style={{ marginLeft: "50px" }}
                    type="checkbox"
                    {...register("additionalTax")}
                    onClick={handleTaxeAdditionalChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {showAdditionalForm && (
                <Row>
                  <Col>
                    <Form.Group controlId="mntBaseTaxeAdd">
                      <Form.Label>Montant</Form.Label>
                      <Form.Control
                        style={{ fontSize: "1em", height: "33px" }}
                        type="text"
                        {...register("mntBaseTaxeAdd")}
                        value={watch("montantTva")}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="codeTaxeAdd">
                      <Form.Label>Retenue</Form.Label>
                      <Form.Select
                        style={{ fontSize: "1em", height: "33px" }}
                        {...register("codeTaxeAdd")}
                        onChange={(e) => {
                          const selectedCodeRetenue = e.target.value;
                          const param = retenueParams.find(
                            (param) => param.codeRetenue == selectedCodeRetenue
                          );
                          setSelectedParam2(param || null);
                        }}
                      >
                        <option value="">Sélectionnez un taxe</option>
                        {retenueParams
                          .filter(
                            (param) => param.categorie.toString() == "Taxe additionnel"
                          )
                          .map((param: RetenueCertifParamType) => (
                            <option key={param.id} value={param.codeRetenue}>
                              {param.codeRetenue}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="tauxTaxeAdd">
                      <Form.Label>Taux Taxe Additionnelle</Form.Label>
                      <Form.Control
                        style={{ fontSize: "1em", height: "33px" }}
                        type="text"
                        {...register("tauxTaxeAdd")}
                        value={selectedParam2?.taux}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="montantTaxeAdd">
                      <Form.Label>Montant Taxe Additionnelle</Form.Label>
                      <Form.Control
                        type="text"
                        {...register("montantTaxeAdd", {
                          required: "Ce champ est requis",
                          pattern: {
                            value: /^\d+(\.\d{1,3})?$/,
                            message:
                              "Format invalide. Utilisez uniquement des chiffres et jusqu'à trois décimales.",
                          },
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setTimeout(() => {
                            handleMontantTaxeAddChange(e);
                          }, 3000);
                        }}
                        disabled
                        style={{ fontSize: "1em", height: "33px" }}
                        value={montantTaxeAdd}
                      />
                      {errors.montantTaxeAdd && (
                        <span style={{ color: "red" }}>
                          {errors.montantTaxeAdd.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="netServiTaxeAdd">
                      <Form.Label>Net Servi Taxe ADD</Form.Label>
                      <Form.Control
                        style={{ fontSize: "1em", height: "33px" }}
                        type="text"
                        {...register("netServiTaxeAdd")}
                        value={netServi2}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Row>
            <Row>
              <div className="d-flex justify-content-end mt-3">
                <Button variant="success" onClick={handleAjouterLigne}>
                  Ajouter une ligne
                </Button>
              </div>
            </Row>
          </Card.Body>
        </Card>

        <div className="d-flex justify-content-end mt-3">
          <Button variant="secondary" className="me-2" onClick={handleReset}>
            Annuler
          </Button>

          <Button type="submit" variant="success">
            Enregistrer le certificat
          </Button>
        </div>
        <CustomModal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Voulez-vous imprimer ce certificat ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ImpressionCertificat numCertificat={currentNumCertificat} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Annuler
            </Button>
          </Modal.Footer>
        </CustomModal>
      </Form>
    </>
  );
};

export default CertificatRetenueForm;
