import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe4Type } from "../../../Types/Entites/Annexe4Type";
import { RootState } from "../../store";

export const createAnnexesApi = createAsyncThunk(
  "annexes/createAnnexesApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post("annexes/createAnnexesApi", data);
      console.log('response',response)
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);
// Action asynchrone pour supprimer toutes les Annexe4 par ID de déclaration
export const deleteAllAnnexesByDeclarationApi = createAsyncThunk(
  "annexes/deleteAllAnnexesByDeclarationApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `annexes/deleteAllAnnexesByDeclarationApi/${data.num_annexe}/${data.declarationId}`
      );
      return {data: response.data };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);


interface Annexe4s {
  annexe4List: Annexe4Type[];
  annexe4ListByDeclaration: Annexe4Type[];
}

const initialState: Annexe4s = {
  annexe4List: [],
  annexe4ListByDeclaration: [],
};

export const annexesSlice = createSlice({
  name: "annexes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexesApi.fulfilled, (state, action) => {
     
    });

    
    

  

    builder.addCase(deleteAllAnnexesByDeclarationApi.fulfilled, (state, action) => {
    
    });
  },
});

export const annexe4List = (state: RootState) => state.annexe4.annexe4List;
export const annexe4ListByDeclaration = (state: RootState) =>
  state.annexe4.annexe4ListByDeclaration;

export default annexesSlice.reducer;
