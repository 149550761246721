import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe4Type } from "../../../Types/Entites/Annexe4Type";
import { RootState } from "../../store";

export const createAnnexe4Api = createAsyncThunk(
  "annexe4/createAnnexe4",
  async (data: Annexe4Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe4/createAnnexe4", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const findAllAnnexe4Api = createAsyncThunk(
  "annexe4/findAllAnnexe4",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe4/findAllAnnexes4");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const updateAnnexe4Api = createAsyncThunk(
  "annexe4/updateAnnexe4",
  async (
    { id, data }: { id: number; data: Annexe4Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe4/updateAnnexe4/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const getAnnexe4ByIdApi = createAsyncThunk(
  "annexe4/getAnnexe4ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe4/getAnnexe4ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const deleteAnnexe4Api = createAsyncThunk(
  "annexe4/deleteAnnexe4",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe4/deleteAnnexe4/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);
export const findAnnexes4ByDeclarationApi = createAsyncThunk(
  "annexe4/findAnnexes4ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe4/findAnnexes4ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



// Action asynchrone pour supprimer toutes les Annexe4 par ID de déclaration
export const deleteAllAnnexesByDeclarationApi = createAsyncThunk(
  "annexes/deleteAllAnnexesByDeclarationApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `annexes/deleteAllAnnexesByDeclarationApi/${data.num_annexe}/${data.declarationId}`
      );
      return {data: response.data };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);


interface Annexe4s {
  annexe4List: Annexe4Type[];
  annexe4ListByDeclaration: Annexe4Type[];
}

const initialState: Annexe4s = {
  annexe4List: [],
  annexe4ListByDeclaration: [],
};

export const annexe4Slice = createSlice({
  name: "annexe4",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe4Api.fulfilled, (state, action) => {
      state.annexe4List.push(action.payload.data);
      state.annexe4ListByDeclaration.push(action.payload.data);
    });

    builder.addCase(findAllAnnexe4Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe4List: action.payload,
      };
    });

    builder.addCase(updateAnnexe4Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe4List.findIndex(
        (annexe4) => annexe4.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe4List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe4ListByDeclaration.findIndex(
        (annexe4) => annexe4.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe4ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });

    builder.addCase(getAnnexe4ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe4List: [action.payload],
      };
    });

    builder.addCase(deleteAnnexe4Api.fulfilled, (state, action) => {
      state.annexe4List = state.annexe4List.filter(annexe4 => annexe4.id !== action.meta.arg);
      state.annexe4ListByDeclaration = state.annexe4ListByDeclaration.filter(annexe4 => annexe4.id !== action.meta.arg);
    });
    builder.addCase(findAnnexes4ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe4ListByDeclaration: action.payload,
      };
    });

    builder.addCase(deleteAllAnnexesByDeclarationApi.fulfilled, (state, action) => {
    
    });
  },
});

export const annexe4List = (state: RootState) => state.annexe4.annexe4List;
export const annexe4ListByDeclaration = (state: RootState) =>
  state.annexe4.annexe4ListByDeclaration;

export default annexe4Slice.reducer;
