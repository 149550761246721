import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RetenueCertifParamType } from "../../../Types/Entites/ReteneueCertifParamType";
import { RootState } from "../../store";
import { DeclarationMensuelleType } from "../../../Types/Entites/DeclarationMensuelleType";

export const createDeclarationMensuelleApi = createAsyncThunk(
  "DeclarationMensuelleRouter/createDeclarationMensuelleApi",
  async (data: any, { rejectWithValue }) => {
    try {

      const response = await axios.post(
        "DeclarationMensuelleRouter/createDeclarationMensuelleApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log('object',error)
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const ValiderDeclarationMensuelleApi = createAsyncThunk(
  "DeclarationMensuelleRouter/ValiderDeclarationMensuelleApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "DeclarationMensuelleRouter/ValiderDeclarationMensuelleApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);
export const findDeclarationMensuelleBySociete_ExerciceApi = createAsyncThunk(
  "DeclarationMensuelleRouter/findDeclarationMensuelleBySociete_ExerciceApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `DeclarationMensuelleRouter/findDeclarationMensuelleBySociete_ExerciceApi/${data.societeid}/${data.exerciceid}`,
        
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface DeclarationMensuelle {
  DeclarationMensuelleList: DeclarationMensuelleType[];
}

const initialState: DeclarationMensuelle = {
  DeclarationMensuelleList: [],
};

export const DeclarationMensuelleSlice = createSlice({
  name: "DeclarationMensuelle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      createDeclarationMensuelleApi.fulfilled,
      (state, action) => {
        state.DeclarationMensuelleList.push(action.payload.data);
      }
    );
    builder.addCase(
      findDeclarationMensuelleBySociete_ExerciceApi.fulfilled,
      (state, action) => {
        state.DeclarationMensuelleList =action.payload
      }
    );
    builder.addCase(
      ValiderDeclarationMensuelleApi.fulfilled,
      (state, action) => {

        const findIndex = state.DeclarationMensuelleList.findIndex(item => item.id === action.payload.data.id);
        if (findIndex !== -1) {
          state.DeclarationMensuelleList[findIndex] = action.payload.data;
        } else {
          state.DeclarationMensuelleList.push(action.payload.data);
        }
    
        console.log("Updated DeclarationMensuelleList:", state.DeclarationMensuelleList);
      }
    );
  },
});

export const {} = DeclarationMensuelleSlice.actions;
export const DeclarationMensuelleList = (state: RootState) =>
  state.declarationMensuelle.DeclarationMensuelleList;

export default DeclarationMensuelleSlice.reducer;
