// exerciceSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { ExerciceType } from "../../../Types/Entites/ExerciceType";

// Action asynchrone pour créer un nouvel exercice
export const createExerciceApi = createAsyncThunk(
  "exercices/createExercice",
  async (data: ExerciceType, { rejectWithValue }) => {
    try {
      const response = await axios.post("exercice/createExercice", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer tous les exercices
export const findAllExercicesApi = createAsyncThunk(
  "exercices/findAllExercices",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("exercice/findAllExercices");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour un exercice
export const updateExerciceApi = createAsyncThunk(
  "exercices/updateExercice",
  async ({ id, data }: { id: number; data: ExerciceType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`exercice/updateExercice/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer un exercice par ID
export const getExerciceByIdApi = createAsyncThunk(
  "exercices/getExerciceById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`exercice/getExerciceById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer un exercice
export const deleteExerciceApi = createAsyncThunk(
  "exercices/deleteExercice",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`exercice/deleteExercice/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findExercicesByDeclarantApi = createAsyncThunk(
  "exercices/findExercicesByDeclarant",
  async (declarantId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`exercice/findExercicesByDeclarant/${declarantId}`);

      return response.data;
      
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Exercices {
  exercicesList: ExerciceType[];
  exercicesListByDeclarant: ExerciceType[]; 
}

const initialState: Exercices = {
  exercicesList: [],
  exercicesListByDeclarant: [], 
};


export const exerciceSlice = createSlice({
  name: "exercices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createExerciceApi.fulfilled, (state, action) => {
      state.exercicesList.push(action.payload.data);
      state.exercicesListByDeclarant.push(action.payload.data);

    });
    builder.addCase(findAllExercicesApi.fulfilled, (state, action) => {
      return {
        ...state,
        exercicesList: action.payload,
      };
    });
    builder.addCase(updateExerciceApi.fulfilled, (state, action) => {
      const updatedIndex =  state.exercicesListByDeclarant.findIndex(
        (exercice) => exercice.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.exercicesListByDeclarant[updatedIndex] = action.payload.data;
        state.exercicesListByDeclarant[updatedIndex] = action.payload.data;

      }


    });
    builder.addCase(getExerciceByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        exercicesList: [action.payload],
      };
    });
    builder.addCase(deleteExerciceApi.fulfilled, (state, action) => {
      state.exercicesList = state.exercicesList.filter(exercice => exercice.id !== action.meta.arg);
      state.exercicesListByDeclarant = state.exercicesListByDeclarant.filter(exercice => exercice.id !== action.meta.arg);


    });
    builder.addCase(findExercicesByDeclarantApi.fulfilled, (state, action) => {
      return {
        ...state,
        exercicesListByDeclarant: action.payload,
      };
    });
  },
});

export const exercicesList = (state: RootState) => state.exercices.exercicesList;
export const exercicesListByDeclarant = (state: RootState) => state.exercices.exercicesListByDeclarant;


export default exerciceSlice.reducer;
