import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  EnteteCertificatsByDeclaration,
  deleteEnteteCertificatApi,
  getEnteteCertificatByDeclarationApi,
  updateBloqueApi,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import {
  LignesCertificatByDeclaration,
  getLigneCertificatByDeclarationApi,
} from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";
import {
  createAnnexe2Api,
  deleteAllAnnexes2ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe2/annexe2Slice";
import {
  createAnnexe3Api,
  deleteAllAnnexes3ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe3/annexe3Slice";

import {
  createAnnexe5Api,
  deleteAllAnnexes5ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe5/annexe5Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { EnteteCertificatType } from "../../_App/Types/Entites/EnteteCertificatType";
import { LigneCertificatType } from "../../_App/Types/Entites/LigneCertificatType";
import ImpressionCertificat from "./ImpressionCetificat";
import { useNavigate } from "react-router-dom";
import { createAnnexesApi, deleteAllAnnexesByDeclarationApi } from "../../_App/Redux/Slices/annexes/annexeSlice";
import { enIE } from "date-fns/locale";

const CustomModal = styled(Modal)`
  .modal-dialog {
    width: 73%;
    max-width: none;
  }
`;

interface EnteteCertificatListProps {
  declarantId: number;
  exerciceId: number;
  declarationId: number;
  showcheck_box:any;
  listnumcertifs:any;
  setlistnumcertifs:any;
  valideExpportxml:any
}

const EnteteCertificatList: React.FC<EnteteCertificatListProps> = ({
  declarantId,
  exerciceId,
  declarationId,
  showcheck_box,
  listnumcertifs,
  setlistnumcertifs,
  valideExpportxml
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const enteteCertificats = useAppSelector(EnteteCertificatsByDeclaration);
  const allLignesCertificat = useAppSelector(LignesCertificatByDeclaration);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showAnnexModal, setShowAnnexModal] = useState(false);
  const [selectedEntete, setSelectedEntete] =
    useState<EnteteCertificatType | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDescending, setSortDescending] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredCertificatsCount, setFilteredCertificatsCount] = useState(0);
  const [checkedAnnexes, setCheckedAnnexes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getEnteteCertificatByDeclarationApi(declarationId));
    dispatch(getLigneCertificatByDeclarationApi(declarationId));
  }, [declarationId]);

  useEffect(() => {
    const filteredCount = enteteCertificats.filter((entete) => {
      const searchString = searchQuery.toLowerCase();
      const enteteDate = new Date(entete.date);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;

      const isWithinDateRange =
        (!startDateObj || enteteDate >= startDateObj) &&
        (!endDateObj || enteteDate <= endDateObj);

      return (
        isWithinDateRange &&
        ((entete.numCertificat?.toLowerCase().includes(searchString) ??
          false) ||
          (entete.Beneficiaire?.name?.toLowerCase().includes(searchString) ??
            false) ||
          (entete.Beneficiaire?.identifiant
            ?.toLowerCase()
            .includes(searchString) ??
            false) ||
          new Date(entete.date).toLocaleDateString().includes(searchString) ||
          entete.montantTotal.toString().includes(searchString) ||
          entete.retenueTotal.toString().includes(searchString) ||
          entete.netServiTotal.toString().includes(searchString))
      );
    }).length;

    setFilteredCertificatsCount(filteredCount);
  }, [enteteCertificats, searchQuery, startDate, endDate]);

  useEffect(()=>{
    if(showcheck_box){
      const data= {etat:true,numCertificat:[enteteCertificats.map(item =>  item.numCertificat)]}
       dispatch(updateBloqueApi(data))

      setlistnumcertifs(enteteCertificats)
    }
  },[showcheck_box,enteteCertificats])
  const handleDeleteClick = async (numCertificat: string) => {
    try {
      const response = await dispatch(deleteEnteteCertificatApi(numCertificat));
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du certificat :", error);
    }
  };

  const handlePrintClick = (entete: EnteteCertificatType) => {
    setSelectedEntete(entete);
    setShowPrintModal(true);
  };

  const handleAnnexClick = () => {
    setShowAnnexModal(true);
  };

  const handleAnnexChange = (annex: string) => {
    setCheckedAnnexes((prev) =>
      prev.includes(annex) ? prev.filter((a) => a !== annex) : [...prev, annex]
    );
  };

  const groupLines = (lines: LigneCertificatType[]): LigneCertificatType[] => {
    const groupedLines: { [key: string]: LigneCertificatType } = {};

    lines.forEach((ligne) => {
      const key = `${ligne.Beneficiaire?.identifiant}-${ligne.RetenueParam?.codeRetenue}-${ligne.typeMontant}`;
      if (!groupedLines[key]) {
        groupedLines[key] = {
          ...ligne,
          montant: 0,
          mntRetenue: 0,
          netServi: 0,
        };
      }
      groupedLines[key].montant += ligne.montant;
      groupedLines[key].mntRetenue += ligne.mntRetenue;
      groupedLines[key].netServi += ligne.netServi;
    });

    return Object.values(groupedLines);
  };

  const handleSaveAnnexes = async () => {
    if (checkedAnnexes.includes("Annexe 2")) {
      const data = { num_annexe: 2, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }
    if (checkedAnnexes.includes("Annexe 3")) {
      const data = { num_annexe: 3, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }
    if (checkedAnnexes.includes("Annexe 4")) {
      const data = { num_annexe: 4, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }
    if (checkedAnnexes.includes("Annexe 5")) {
      const data = { num_annexe: 5, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }
    if (checkedAnnexes.includes("Annexe 6")) {
      const data = { num_annexe: 6, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }
    if (checkedAnnexes.includes("Annexe 7")) {
      const data = { num_annexe: 7, declarationId: declarationId };
      const response = await dispatch(deleteAllAnnexesByDeclarationApi(data));
    }

    const result = allLignesCertificat.reduce<
      Record<string, LigneCertificatType[]>
    >((acc, ligne) => {
      const affectation = ligne.RetenueCertifParam?.affectationAnx;
      const beneficiaire = ligne.Beneficiaire?.id;
      if (affectation !== undefined && beneficiaire) {
        const key = `${affectation}-${beneficiaire}`;
        acc[key] = acc[key] || [];

        acc[key].push(ligne);
      }

      return acc;
    }, {});

    // Maintenant, nous allons faire la somme des montants pour chaque clé
    const summedResult = Object.entries(result).map(([key, lignes]) => {
      const totalmontant = Number(
        lignes.reduce((sum, ligne) => sum + (ligne.montant || 0), 0).toFixed(3)
      );
      const totalmntRetenue = Number(
        lignes
          .reduce((sum, ligne) => sum + (ligne.mntRetenue || 0), 0)
          .toFixed(3)
      );
      const totalmntnetServi = Number(
        lignes.reduce((sum, ligne) => sum + (ligne.netServi || 0), 0).toFixed(3)
      );

      return {
        Annexe: key.split("-")[0],
        totalmontant,
        totalmntRetenue,
        totalmntnetServi,
        declaration: declarationId,
        typeIdentifiant: lignes[0].Beneficiaire?.typeIdentifiant || 0,
        identifiant: lignes[0].Beneficiaire?.identifiant || "",
        categorie: lignes[0].Beneficiaire?.categorie || 0,
        nom: lignes[0].Beneficiaire?.name || "",
        activity: lignes[0].Beneficiaire?.activity || "",
        adresse: lignes[0].Beneficiaire?.adresse || "",
        typeMontantServis: lignes[0].typeMontant ?? 0,
        montantServisActNonComm: lignes[0].montant,
        retenuOper: lignes[0].mntRetenue,
        netServi: lignes[0].netServi,
      };
    });
    console.log(summedResult)
   
    const response = await dispatch(createAnnexesApi(summedResult));

    setShowAnnexModal(false);
  };

  const handleCancelAnnexes = () => {
    setShowAnnexModal(false);
  };

  const handleEdit = (numCertificat: string) => {
    navigate(
      `/certificatRetenue/${exerciceId}/${declarantId}/${declarationId}/${numCertificat}`
    );
  };
  const checkednumCertificat =async  (Certificat: any) => {
    const findindex = listnumcertifs.findIndex((item: any) => item.numCertificat === Certificat.numCertificat);
    const copie = [...listnumcertifs];
  
    if (findindex !== -1) {
      //debloqué 
      const data= {etat:false,numCertificat:[Certificat.numCertificat]}
       await dispatch(updateBloqueApi(data))
      copie.splice(findindex, 1); 
    } else {
      const data= {etat:true,numCertificat:[Certificat.numCertificat]}
      await dispatch(updateBloqueApi(data))
      copie.push(Certificat);
    }
    setlistnumcertifs(copie);
  };
  
  return (
    <>
      <Card style={{ backgroundColor: "#f0f4fc" }}>
        <Card.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Card.Title style={{ justifyContent: "flex-start" }}>
                Nombre des certificats : {filteredCertificatsCount}
              </Card.Title>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="text"
                placeholder="Rechercher..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: "200px",
                  textAlign: "left",
                  marginRight: "10px",
                }}
              />
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Du"
                style={{ width: "200px", marginRight: "10px" }}
              />
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="À"
                style={{ width: "200px", marginRight: "10px" }}
              />
              <Button
                variant="success"
                onClick={() => setSortDescending(!sortDescending)}
                style={{ marginLeft: "15px" }}
              >
                {sortDescending
                  ? "Date la plus ancienne"
                  : "Date la plus récente"}
              </Button>
              <Button
                variant="warning"
                onClick={handleAnnexClick}
                style={{ marginLeft: "10px" }}
              >
                Générer les annexes
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th>Numéro Certificat</th>
            <th>Beneficiaire</th>
            <th>Identifiant</th>
            <th>Date</th>
            <th>Montant Total</th>
            <th>Retenue Total</th>
            <th>Net Servi Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {enteteCertificats
            .filter((entete) => {
              const searchString = searchQuery.toLowerCase();
              const enteteDate = new Date(entete.date);
              const startDateObj = startDate ? new Date(startDate) : null;
              const endDateObj = endDate ? new Date(endDate) : null;

              const isWithinDateRange =
                (!startDateObj || enteteDate >= startDateObj) &&
                (!endDateObj || enteteDate <= endDateObj);

              return (
                isWithinDateRange &&
                ((entete.numCertificat?.toLowerCase().includes(searchString) ??
                  false) ||
                  (entete.Beneficiaire?.name
                    ?.toLowerCase()
                    .includes(searchString) ??
                    false) ||
                  (entete.Beneficiaire?.identifiant
                    ?.toLowerCase()
                    .includes(searchString) ??
                    false) ||
                  new Date(entete.date)
                    .toLocaleDateString()
                    .includes(searchString) ||
                  entete.montantTotal.toString().includes(searchString) ||
                  entete.retenueTotal.toString().includes(searchString) ||
                  entete.netServiTotal.toString().includes(searchString))
              );
            })
            .sort((a, b) => {
              if (sortDescending) {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              } else {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              }
            })
            .map((entete: EnteteCertificatType) => (
              <tr key={entete.numCertificat}>
                {showcheck_box ? (
                  <td>
                    <input  type="checkbox" 
                            checked={listnumcertifs.find((item:any)=>item.numCertificat ===entete.numCertificat)}
                            onChange={()=>checkednumCertificat(entete)}
                            />
                  </td>
                ):<td></td>}
                <td>{entete.numCertificat}</td>
                <td>{entete.Beneficiaire && entete.Beneficiaire.name}</td>
                <td>
                  {entete.Beneficiaire && entete.Beneficiaire.identifiant}
                </td>
                <td>{new Date(entete.date).toLocaleDateString()}</td>
                <td>{entete.montantTotal}</td>
                <td>{entete.retenueTotal}</td>
                <td>{entete.netServiTotal}</td>
                    <td>
                    {!entete.bloquer &&
                      <>    <span
                    style={{
                      fontSize: "20px",
                      color: "blue",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => handleEdit(entete.numCertificat)}
                  >
                    <i className="fas fa-edit"></i>
                  </span>
                  <span
                    className="action-icon danger"
                    onClick={() => handleDeleteClick(entete.numCertificat)}
                    title="Supprimer le certificat"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
</> }
              
                  <span
                    style={{
                      fontSize: "20px",
                      color: "green",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlePrintClick(entete)}
                  >
                    <i className="fas fa-download"></i>
                  </span>
                </td>
              
              </tr>
            ))}
        </tbody>
      </Table>
      {showcheck_box &&
       <Button variant="info" onClick={  valideExpportxml   }>
       Exporter XML
     </Button>
      }

      <CustomModal
        show={showPrintModal}
        onHide={() => setShowPrintModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Impression du Certificat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEntete && (
            <ImpressionCertificat
              numCertificat={selectedEntete.numCertificat}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPrintModal(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </CustomModal>

      <Modal show={showAnnexModal} onHide={() => setShowAnnexModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Générer les annexes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {["Annexe 2", "Annexe 3", "Annexe 4", "Annexe 5", "Annexe 6"].map(
                (annex) => (
                  <Col md={6} key={annex}>
                    <Form.Check
                      type="checkbox"
                      label={annex}
                      checked={checkedAnnexes.includes(annex)}
                      onChange={() => handleAnnexChange(annex)}
                    />
                  </Col>
                )
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelAnnexes}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleSaveAnnexes}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnteteCertificatList;
